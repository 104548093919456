import React, { useState } from 'react';
import { BACKEND_API_URL, BACKEND_IP_ADDRESS } from '../data/constant'


const SetUserName = () => {
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const handleLogin = async () => {
    try {
      // Send a POST request to your API for login
      const data = { "username": username }
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Specify the content type as JSON
          credentials: 'include',
        },
        body: JSON.stringify(data), // Convert the data object to JSON string
      };

      fetch(BACKEND_API_URL + 'setusername', requestOptions)
        .then((response) => {
          if (!response.ok) {
            console.log(response)
            throw new Error('Network response was not ok');
          }

          return response.json(); // Parse the response JSON
        })
        .then((responseData) => {
          // Handle the response data here (responseData is the parsed JSON response)
          console.log("response from login", responseData)
          const finalURL = responseData.url;
          window.location.href = finalURL; // Redirect the window to the final URL
          window.location.href = 'https://' + BACKEND_IP_ADDRESS;
        })
        .catch((error) => {
          // Handle any errors that occurred during the fetch
          console.error('Fetch error:', error);
        });
    } catch (err) {
      setError('Login failed. Please check your credentials.');
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h2>Set Your UserName</h2>
      {error && <p className="error">{error}</p>}
      <div>
        <label>Username:</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <button onClick={handleLogin}>Confirm</button>
    </div>
  );
};

export default SetUserName;
