import Grid from '@mui/joy/Grid';
import Button from '@mui/joy/Button';
import Card from "../component/card";
import personalizd_learning_img from '../data/personalizd_learning.jpeg';
import hassel_free_setup_img from '../data/hasselFree.jpeg';
import community_img from '../data/community.jpeg';
import demo_gif from '../data/demo.gif'


function cardImage(img, alt) {
    return <img
        src={img}
        srcSet={img}
        loading="lazy"
        alt={alt}
    />
}

const WelcomePage = () => {
    return (
        <Grid container spacing={2} style={{ height: "100%", width: "100%" }}>
            <Grid xs={12} style={{ margin: "auto", textAlign: "center" }}>
                <div style={{
                    display: "block",
                    textAlign: "center",
                    margin: "auto",
                    maxWidth: "100%", maxHeight: "100%"
                }}>
                    <h2 style={{ fontSize: "3.5vw" }}> Learning DevOps made </h2>
                    <h1 style={{ color: "yellow", fontSize: "5.5vw" }}>EASY</h1>
                    <div>
                        Explore the world of DevOps with our hands on excercise.
                    </div>
                    </div>
                </Grid>
                <Grid xs={12} style={{ margin: "auto", textAlign: "center" }}>
                    <div style={{ display: "inline-flex", marginTop: "3%", marginBottom: "3%",
                  margin: "auto",
                  maxWidth: "100%", maxHeight: "100%" }}>
                        {Card("Hands On Practical DevOps Excercise", "Learn DevOps in a terminal environment"
                            , cardImage(personalizd_learning_img, "Hands On Learning"))}
                        {Card("Hassle Free Setup", "No need to install any software, just open your browser and start learning", cardImage(hassel_free_setup_img, "Hassle Free Setup"))}
                        {Card("Complete and learn with a Community", "You are not alone in this journey, learn with a community of learners", cardImage(community_img, "Community"))}
                    </div>
                    <div>
                        <Button
                            color="primary"
                            onClick={function () {
                                // window.location.href = "https://forms.gle/DExEKuuNWHriSd7j9"
                                window.location.href = "https://devopspenguin.com/Practice_Now"
                            }}
                            size="lg"
                            variant="soft"
                        > Practice Now </Button>
                    </div>
                </Grid>
            <Grid xs={12} style={{ margin: "auto", textAlign: "center" }}>
                <img style={{ maxWidth: "50%", maxHeight: "50%" }} src={demo_gif}></img>

            </Grid>
        </Grid>
    );
};

export default WelcomePage;