import { useEffect } from 'react';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Button from '@mui/joy/Button';
import {
  Link
} from "react-router-dom";
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import ModeToggle from "../other/modetoggle"
import LoginPanel from './loginpanel';
import Grid from '@mui/joy/Grid';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';


export default function NavBar(arr) {
  const myCookieData = Cookies.get('user');
  var loggedin = false
  if (myCookieData != null) {
    loggedin = true
  }
  const location = useLocation();
  useEffect(() => {
  }, [location]); //
  return (
    <div style={{ display: "inline-flex", width: "100%"}}>
      <div style={{ textAlign: "left", display: "inline-flex", maxWidth: "30%", maxHeight: "10%", justifyContent: "flex-start" }}>
        <Link style={{ color: 'inherit', textDecoration: 'inherit', marginRight: "5%", marginLeft: "3%", textAlign: "center" }} to={"/"} >
          <h1 style={{ fontSize: "1vw" }}>DevOps Penguin</h1>
          
        </Link>
        <div style={{ width: "5vw", marginTop: "2vh"}}>
          {ModeToggle()}
        </div>
      </div>
      <div style={{ display: "inline-flex", justifyContent: "flex-end", marginLeft: "auto", width: "auto" }}>
        {arr.map((item, index) => (
          <div style={{ width: `${100/arr.length}%`}}>
            <Link style={{ color: 'inherit', textDecoration: 'inherit', width: "inherit" }} to={"/" + item.text.replace(" ", "_")} >
              <Button variant="plain" sx={{ width: "fit-content" }}>
                <div style={{ fontSize: "1vw", width: "inherit" }}>{item.text}</div>
              </Button>
            </Link>
          </div>
        ))}
        <div style={{width: `${100/arr.length}%`}}>
          {loggedin ?
            // <Button variant="plain" sx={{ fontSize: "100%", height: "100%", width: "100%" }}>
            <Select placeholder={myCookieData} sx={{ indicator: "none", width: "100%" }}>
              <Option>
                <Button variant="plain">
                  <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={"/profile"}>
                    Profile
                  </Link>
                </Button>
              </Option>
              <Option>
                <Button variant="plain">
                  <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={"/logout"} >
                    Logout
                  </Link>
                </Button>
              </Option>
            </Select>
            : <LoginPanel />}
        </div>
      </div>
    </div>
  );
}