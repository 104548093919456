import React, { useState, useEffect } from 'react';
import { BACKEND_API_URL } from '../data/constant';
import { setGetRequest } from '../other/setdataRequest';


const Leaderboard = () => {
  const [users, setUsers] = useState({});
  useEffect(() => {
    console.log(BACKEND_API_URL + "leaderboard")
    setGetRequest(BACKEND_API_URL + "leaderboard", setUsers)
  }, [])

  useEffect(() => {
    console.log("users", users['records'])
  }, [users])

  return (
    <div style={{textAlign: "center", height: "100%", width: "100%"}}>
      <h2>Leaderboard</h2>
      <table style={{margin: "auto"}}>
        <thead >
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>

        {users.records === undefined || users.records=== null? (
            <tr>
              <td colSpan="3">Loading...</td>
            </tr>
          ) : (
            users.records.map((entry, ind) => (
              <tr key={ind + 1}>
                <td>{ind + 1}</td>
                <td>{entry.username}</td>
                <td>{entry.points}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Leaderboard;
