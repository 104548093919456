import { BACKEND_API_URL } from "../data/constant"

function checkStatus(url) {
    const intervalFunc = new Promise(function (resolve, reject) {

        const interval = setInterval(async function myfunc() {
            const response = await fetch(url);
            const text = await response.json();
            console.log(`checking status of ${url}, status is ${text['Status']}`)
            if (text['Status'] == "completed") {
                clearInterval(interval);
                resolve(text)
            }
        }, 5000)
    })
    return intervalFunc
}


export function setGetRequest(apiUrl, setData) {
    // Fetch the data from the API

    fetch(apiUrl, {
        credentials: 'include', // Include cookies in the request turn on in prod
    }
    )
        .then((response) => {
            console.log("response in get request", response);
            if (!response.ok) {

                throw new Error('Network response was not ok');
            }

            return response.json();
        }).then((data) => {
            console.log("setting data", data)
            setData(data);
        }
        )

        .catch((error) => {
            console.error('Error fetching data:', error);
        });

}


export function setPostRequest(apiUrl, postData, setData, callback) {
    // Fetch the data from the API
    // console.log("call back func in post request", callback.toString())
    fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include', // Include cookies in the request,
        body: JSON.stringify(postData),
    })
        .then((response) => {
            if (!response.ok) {
                console.log(response)
                throw new Error('Network response was not ok');
            }
            // console.log(apiUrl)
            var res = response.json()
            return res
        })
        .then((responseData) => {
            // Handle the response data here
            console.log('Response data:', responseData);
            if (responseData['Result'] != undefined) {
                console.log("function name of setData", setData.name)
                console.log("set data in post request", responseData)
                setData(responseData)
                return responseData
            }
            else {
                var getUrl = BACKEND_API_URL + "check-task/" + responseData['task_id']
                checkStatus(getUrl).then(
                    (data) => {
                        if (data['Status'] == 'completed') {
                            console.log("seting data in check status : ", data)
                            if (data)
                                setData(data)
                            return data
                        }
                    }
                )
            }
        })
        .catch((error) => {
            console.error('Error sending POST request:', error);
        });
}