import { Routes, Route, useNavigate } from "react-router-dom";
import WelcomePage from "../page/main";
import NavBar from '../component/navbar';
import Grid from '@mui/joy/Grid';
import QuestionPanel from "../component/questionpanel"
import SetUserName from "../component/login";
import Leaderboard from "../component/leaderboard";
import Cookies from 'js-cookie';
import CollapsibleList from "../component/collapsiblelist";
import AboutUs from "../page/about";
import { BACKEND_API_URL } from "../data/constant";
import { styled } from '@mui/joy/styles';
import TierComparison from "../component/proj";
import CancelCheckout from "../page/cancel";
import CheckoutSuccess from "../page/success";
import Profile from "../page/profile";
// import bg_img from '../data/bg_img.png';
// import bg_img_ex from '../data/bg_dark.png';
import Footer from '../component/footer';
import { useColorScheme } from '@mui/joy/styles';
import { height, margin, minHeight } from "@mui/system";
import { position } from "@chakra-ui/react";


export default function MyRoutes() {
   const navigate = useNavigate()
  //  const location = useLocation();

  //  const handleNavigate = (route) => {
  //    // Use the navigate function to programmatically navigate to a different route
  //    navigate(route);

  //    // Access information about the current route from the location object
  //    console.log('Current pathname:', location.pathname);
  //  };
  const { mode, setMode } = useColorScheme();
  const StatRoot = styled('body', {
    name: 'JoyStat', // The component name
    slot: 'root', // The slot name
  })(({ theme }) => ({
    backgroundColor: mode == "light" ? "white" : "black", 
    color: theme.vars.palette.primary[500],
    height: "100%",
    width: "100%",
    // backgroundImage: mode == "light" ? `url(${bg_img})` : `url(${bg_img_ex})`,
    backgroundSize: 'cover', // Adjust as needed
    backgroundPosition: 'center', // Adjust as needed
  }));

 
  const navItem = [
    { text: "Practice Now" },
    { text: "LeaderBoard" },
    { text: "Pro" },
    { text: "Blog" },
    {
      text: "Join Discord"
    },
    // { text: "Login" }
  ]
  function SetWebLink() {
    window.location.href = 'https://discord.gg/h4H6DkYy';
    return <div></div>;
  }

  function Logout() {
    fetch(BACKEND_API_URL + 'logout')
      .then(response => {
        console.log(response)
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // Use response.url to check the final URL after redirection
        return response.json(); // Assuming the response is in JSON format
      })
      .then(data => {
        // Handle the JSON data from the final response
        console.log(data);
        navigate("/");
      })
      .catch(error => {
        // Handle errors that occurred during the request or redirection
        console.error('Fetch error:', error);
      });
      return <div></div>;
  }

  return (
    <StatRoot style={{ overflow: "auto", height: "100%", width: "100%" }}>
      <Grid container spacing={2} style={{ height: "100%", width: "100%" }}>
        <Grid xs={12} style={{ height: "fit-content", width: "100%", margin: "auto", top:"0", minHeight: "5%"}}>
          {NavBar(navItem)}
        </Grid>
        <Grid xs={12} style={{ height: "fit-content", width: "100%" , margin: "auto", minHeight: "90%"}}>
          <Routes>
            <Route path="/Practice_Now" element={ < CollapsibleList />} />
            <Route path="/Leaderboard" element={<Leaderboard />} />
            <Route path="/About_Us" element={<AboutUs />}/>
            <Route path="/blog" element={<div>Blog</div>}/>
            <Route path="/Join_Discord" element={<SetWebLink />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/" element={<WelcomePage />} />
            <Route path="/set_username" element={<SetUserName />} />
            <Route path="/Pro" element={<TierComparison />} />
            <Route path="question/:id" element={<QuestionPanel />} />
            <Route path="/cancel" element={<CancelCheckout />} />
            <Route path="/success" element={<CheckoutSuccess />} />
            <Route path="/profile" element={< Profile/>} />
          </Routes>
        </Grid>
        <Grid xs={12} style={{ height: "fit-content", width: "100%", bottom:"0", minHeight: "5%"}}>
          <Footer style={{height: '100%', widht: "100%"}}/>
        </Grid>
      </Grid>
    </StatRoot>
  );
}