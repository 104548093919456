import './App.css';
import { CssVarsProvider } from '@mui/joy/styles';
import theme from './other/theme';
import Router from "./other/router";
import { BrowserRouter } from "react-router-dom";
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ServerSkip extends Component {
  static propTypes = {
    children: PropTypes.element,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  }

  static defaultProps = {
    component: 'div',
  }

  state = { mounted: false };

  componentDidMount() {
    this.setState({ mounted: true }); //eslint-disable-line
  }

  render() {
    const { children, ...otherProps } = this.props;
    return (
      <div ref="container" {...otherProps} >
        {this.state.mounted && children}
      </div>
    );
  }
}
function App() {
  return (
    <ServerSkip className="App">
      <BrowserRouter style={{
      }}
        
      >
        <CssVarsProvider theme={theme} defaultMode="dark" >
          <Router />
        </CssVarsProvider>
      </BrowserRouter>
    </ServerSkip>
  );
}

export default App;
