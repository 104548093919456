import { useColorScheme } from '@mui/joy/styles';
import Button from '@mui/joy/Button';
import Switch from '@mui/joy/Switch';
import FormLabel from '@mui/joy/FormLabel';

// import nerdPenguin from '../data/nerd_penguin-transformed.png';
// import sadPenguin from '../data/penguin.png'
export default function ModeToggle() {
  const { mode, setMode } = useColorScheme();
  return (
    <div>
    <Switch
      variant="outlined"
      color="neutral"
      onClick={() => setMode(mode === 'dark' ? 'light' : 'dark')}
      style={{padding: "0%",   position: "relative"}}
    >
      
      {/* <img src={nerdPenguin} alt="Penguin doing DevOps" style={{height: "80%", width: "30%"}}/> */}
      {/* <img src={sadPenguin} alt="Penguin doing DevOps" style={{height: "80%", width: "30%"}}/> */}
    </Switch>
    <FormLabel>{mode === 'dark' ? 'Turn light' : 'Turn dark'}</FormLabel>
    </div>
  );
}