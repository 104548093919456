import { extendTheme } from '@mui/joy/styles';
const theme = extendTheme({
  components: {
    JoySelect: {
      defaultProps: {
        indicator: '',
      },
    },
  },
  styleOverrides: {
    root: {
      zIndex: 0,
    },
  }
});

export default theme;