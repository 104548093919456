import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import GitHubIcon from '@mui/icons-material/GitHub';
import GoogleIcon from '@mui/icons-material/Google';
import { BACKEND_API_URL } from '../data/constant';
import LoginIcon from '@mui/icons-material/Login';
import { useEffect } from 'react';
function logInWithGithub() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json', // Specify the content type as JSON
            credentials: 'include',

        },
    };

    fetch(BACKEND_API_URL + 'oauthlogin/', requestOptions)
        .then((response) => {
            if (!response.ok) {
                console.log(response)
                throw new Error('Network response was not ok');
            }

            return response.json(); // Parse the response JSON
        })
        .then((responseData) => {
            console.log("response from login", responseData)
            const finalURL = responseData.url;
            window.location.href = finalURL;
        })
        .catch((error) => {
            console.error('Fetch error:', error);
        });
}

const GoogleButton = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);


    return (
        <>
            <div id="g_id_onload"
                data-client_id="1098827569271-h56raeusf464a2pjiin7tqqe36pad36r.apps.googleusercontent.com"
                data-ux_mode="redirect"
                data-login_uri={BACKEND_API_URL + 'googleoauthcallback/'}
                data-auto_prompt="false"
            >

                <Button

                    variant="outlined"
                    color="neutral"
                    startDecorator={<GoogleIcon />}
                >
                    Sign In
                    <div class="g_id_signin" style={{
                        opacity: "0"
                        , position: "absolute",
                        pointerEvents: "auto"

                    }}>
                    </div>


                </Button>
            </div>
        </>
    );
};

export default function LoginPanel() {
    const [open, setOpen] = React.useState(false);
    return (
        <React.Fragment>
            <Button
                variant="outlined"
                color="neutral"
                startDecorator={<LoginIcon />}
                onClick={() => setOpen(true)}
            >
                Sign In
            </Button>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog sx={{ textAlign: "center" }}>
                    <Button
                        variant="outlined"
                        color="neutral"
                        startDecorator={<GitHubIcon />}
                        onClick={() => logInWithGithub()}
                    >
                        Sign In
                    </Button>
                    <GoogleButton />

                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}