import React from 'react';

const CheckoutSuccess = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "5%" }}>
      <h1>Checkout Successful</h1>
      <p>Thank you for your purchase!</p>
      {/* You can add additional content or links here */}
    </div>
  );
};

export default CheckoutSuccess;
