import { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import { setGetRequest } from "../other/setdataRequest"
import { BACKEND_API_URL } from '../data/constant';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function groupQuestionsByTopic(jsonData) {
  const groupedQuestions = {};
  console.log("json data", jsonData)
  jsonData.forEach(question => {
    const { topic, ...rest } = question;

    if (!groupedQuestions[topic]) {
      groupedQuestions[topic] = { topic, questions: [] };
    }
    rest['topic'] = topic.toLowerCase()
    groupedQuestions[topic].questions.push(rest);
  });
  console.log("grouped question", groupedQuestions)

  return Object.values(groupedQuestions);
}


export default function CollapsibleList() {
  const tierCookieData = Cookies.get('tier');
  const location = useLocation();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState({});
  var [open, setOpen] = useState(Array(100).fill(false));
  const [outer_item, setOuterItem] = useState([]);
  useEffect(() => {
    if (location.pathname.includes('/Practice_Now') && outer_item.length == 0) {
      console.log(BACKEND_API_URL + "recordall/questions")
      setGetRequest(BACKEND_API_URL + "recordall/questions", setQuestions)
    }
  }, [location])
  useEffect(() => {
    if (questions['records'] == undefined) {
      return
    }
    console.log("questions", questions['records'])
    var q = []
    if (tierCookieData == "pro") {
       q = questions['records']
    }else{
      q = questions['records'].filter(item => item.tier == "free")
      //  .sort((a) => a.id)
    }
    console.log("after processing ", q)
    setOuterItem(groupQuestionsByTopic(q))
  }, [questions])
  const handleQuestionClick = (index) => {
    const updatedOpenState = [...open]; // Make a copy of the open state array
    updatedOpenState[index] = !updatedOpenState[index]; // Update only the specified index
    setOpen(updatedOpenState); // Set the updated array as the new state
    // console.log("changed index to " , open[index])
  };
  useEffect(() => {
  }, [open]);
  return (
    <Box
      sx={{
        width: 320,
        pl: '24px',
        textAlign: "center",
        margin: "auto"
      }}
    >
      <List
        size="sm"
        sx={(theme) => ({
          // Gatsby colors
          '--joy-palette-primary-plainColor': '#8a4baf',
          '--joy-palette-neutral-plainHoverBg': 'transparent',
          '--joy-palette-neutral-plainActiveBg': 'transparent',
          '--joy-palette-primary-plainHoverBg': 'transparent',
          '--joy-palette-primary-plainActiveBg': 'transparent',
          [theme.getColorSchemeSelector('dark')]: {
            '--joy-palette-text-secondary': '#635e69',
            '--joy-palette-primary-plainColor': '#d48cff',
          },
          '--List-insetStart': '32px',
          '--ListItem-paddingY': '0px',
          '--ListItem-paddingRight': '16px',
          '--ListItem-paddingLeft': '21px',
          '--ListItem-startActionWidth': '0px',
          '--ListItem-startActionTranslateX': '-50%',
          [`& .${listItemButtonClasses.root}`]: {
            borderLeftColor: 'divider',
          },
          [`& .${listItemButtonClasses.root}.${listItemButtonClasses.selected}`]: {
            borderLeftColor: 'currentColor',
          },
          '& [class*="startAction"]': {
            color: 'var(--joy-palette-text-tertiary)',
          },
        })}
      >
        {outer_item.map((item, index) => (

          <ListItem
            nested
            sx={{ my: 1 }}
            startAction={
              <IconButton
                variant="plain"
                size="sm"
                color="neutral"
                onClick={() => { handleQuestionClick(index) }}
              >
                <KeyboardArrowDown
                  sx={{ transform: open ? 'initial' : 'rotate(-90deg)' }}
                />
              </IconButton>
            }
          >
            <ListItem>
              <Typography
                level="inherit"
                sx={{
                  fontWeight: open ? 'bold' : undefined,
                  color: open ? '#bf3a5e' : 'inherit',
                }}
              >
                {item.topic}
              </Typography>
              <Typography component="span" level="body-xs">
                {item.questions.length}
              </Typography>
            </ListItem>
            <List sx={{ '--ListItem-paddingY': '8px' }}>
              {open[index] == true && (
                item.questions.map((inner_item) => {
                  return (
                    <ListItem>
                      <ListItemButton
                        // component={Link} // <-- pass Link as component
                        // to={"/question/" + inner_item.id}
                        onClick={() => { 
                          navigate("/question/" + inner_item.id ,{state:inner_item});
                        }}
                      >
                        {inner_item["question_title"]}
                      </ListItemButton>
                    </ListItem>
                  )
                })
              )}
            </List>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}