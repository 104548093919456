import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';


export default function BasicCard(title, body, img) {
  return (
    <Card sx={{ width: 320, marginRight: "3%", marginLeft: "3%" }}>
      <div>
        <Typography level="title-lg">{title}</Typography>
        <Typography level="body-sm">{body}</Typography>
      </div>
      <AspectRatio minHeight="120px" maxHeight="200px">
        {img}
      </AspectRatio>
    </Card>
  );
}