import React from 'react';
import { BACKEND_API_URL, BACKEND_IP_ADDRESS } from '../data/constant'
import Button from '@mui/joy/Button';

const CheckoutForm = () => {
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Send a POST request to your API for login
      const data = {}
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Specify the content type as JSON
          credentials: 'include',
        },
        body: JSON.stringify(data), // Convert the data object to JSON string
      };

      fetch(BACKEND_API_URL + 'create-checkout-session', requestOptions)
        .then((response) => {
          if (!response.ok) {
            console.log(response)
            throw new Error('Network response was not ok');
          }

          return response.json(); // Parse the response JSON
        })
        .then((responseData) => {
          // Handle the response data here (responseData is the parsed JSON response)
          console.log("response from checkout", responseData)
          const finalURL = responseData.url;
          window.location.href = finalURL; // Redirect the window to the final URL
        })
        .catch((error) => {
          // Handle any errors that occurred during the fetch
          console.error('Fetch error:', error);
        });
    } catch (err) {
      console.error('Login failed. Please check your credentials.');
    }
  };

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <Button type="submit"
        size="lg"
        variant="soft"
        color="primary"
      >Checkout</Button>
    </form>
  );
};

const tierData = [
  {
    title: 'Free Tier',
    price: '€0/month',
    features: ['Access Free Topics', 'Discord Community help', '5 session per day'],
  },
  {
    title: 'Pro Tier',
    price: '€5/month',
    features: ['Access to Permium Topics', 'playground for all tools', 'Unlimited sessions', 'All features of Free Tier'],
  },
];

const TierComparison = () => {
  return (
    <div style={styles.container}>
      {tierData.map((tier, index) => (
        <div key={index} style={styles.tier}>
          <h2 style={styles.title}>{tier.title}</h2>
          <div style={styles.price}>{tier.price}</div>
          <ul style={styles.features}>
            {tier.features.map((feature, i) => (
              <li key={i}>{feature}</li>
            ))}
            {index === 1 && <CheckoutForm />}
          </ul>
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: "100%",
  },
  tier: {
    border: '1px solid #e0e0e0',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    margin: '10px',
    textAlign: 'center',
    flex: 1,
    minWidth: '30%',
    // background: 'white',
  },
  title: {
    // fontSize: '24px',
    fontSize: '2.5vw',
    fontWeight: 'bold',
    // color: '#333',
    margin: '10px 0',
  },
  price: {
    // fontSize: '28px',
    fontSize: '3vw',
    fontWeight: 'bold',
    // color: '#27ae60',
  },
  features: {
    listStyle: 'none',
    padding: 0,
  },
};

export default TierComparison;