import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { setGetRequest } from '../other/setdataRequest';
import { BACKEND_API_URL } from '../data/constant';

const UserProfile = () => {
    const [subscriptionInfo, setSubscriptionInfo] = useState({});
    const [cancellingSubscription, setCancellingSubscription] = useState(false);

    useEffect(() => {
        // Simulate fetching user profile and subscription info from your server
        // Replace this with actual API calls
        setGetRequest(BACKEND_API_URL + "subscription", setSubscriptionInfo)
    }, []);
    useEffect(() => {
        console.log("subscription info", subscriptionInfo)
    }, [subscriptionInfo])
    useEffect(() => {
        console.log("cancelling subscription", cancellingSubscription)
    }, [cancellingSubscription])

    const cancelSubscription = () => {
        // Simulate cancelling the subscription
        // Replace this with actual API call to cancel the subscription
        setCancellingSubscription(true);
        fetch(BACKEND_API_URL + "cancel-subscription", { method: 'POST' })
            .then((response) => response.json())
            .then((data) => {
                setCancellingSubscription(false);
                // Handle the response (e.g., show a success message)
                if (data.message != undefined) {
                    window.location.href =  window.location.href;
                } else {
                    alert('Failed to cancel subscription. Please try again.');
                }
            });
    };

    return (
        <div style={{ textAlign: "center", marginTop: "5%" }}>
            <h2>User Profile</h2>
            <div>
                <strong>Name:</strong> {Cookies.get('user')}
            </div>
            <h2>Subscription Information</h2>
            <div>
                <strong>Status:</strong> {subscriptionInfo.status == null ? "No Subscription" : subscriptionInfo.status}
            </div>
            {subscriptionInfo.status === 'active' &&
                <div>
                    <div>
                        <strong>Next Renewal Date:</strong> {subscriptionInfo.nextRenewalDate}
                    </div>
                    <div>
                        <button
                            onClick={cancelSubscription}
                            disabled={cancellingSubscription}
                        >
                            {cancellingSubscription ? 'Cancelling...' : 'Cancel Subscription'}
                        </button>
                    </div>
                </div>
            }
            {
                subscriptionInfo.status === 'canceled' &&
                <div>
                    <strong>Valid Until:</strong> {subscriptionInfo.nextRenewalDate}
                </div>

            }
        </div>
    );
};

export default UserProfile;