import React from 'react';
import logo_img from '../data/logo_devopspenguin.jpg'
const AboutUs = () => {
  return (
      <div style={{textAlign: "center", margin: "auto", marginTop: "15%"}}>

        <img style={{ maxWidth: "25%", maxHeight: "25%" }}  srcSet={logo_img}></img>
        
        <h2>
          Welcome to DevOps Penguin! We're passionate about DevOps and are dedicated to providing a practical and interactive learning experience for individuals and organizations.
        </h2>

        <h3>
          Our mission is to empower aspiring DevOps engineers, seasoned professionals, and organizations to achieve their DevOps goals. We offer a wide range of labs, tutorials, articles, and certification support.
        </h3>

        <h3>
          Join our community, ask questions, and share your insights as you embark on a DevOps journey with us. We're excited to have you here!
        </h3>

        <h4>
          Founder of DevOps Penguin
        </h4>
      </div>
  );
};

export default AboutUs;
