import React from 'react';
import { Box, Typography, Link, Grid } from '@mui/joy';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'background.level1',
        py: 3,
        px: 2,
        mt: 'auto',
        borderTop: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} sm={6}>
          <Typography level="body2" color="text.secondary">
            © {new Date().getFullYear()} DevOpsPenguin. All rights reserved.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
          <Link
            href="/privacy-policy"
            color="neutral"
            level="body2"
            underline="none"
            sx={{ mx: 1 }}
          >
            Privacy Policy
          </Link>
          <Link
            href="/terms-of-service"
            color="neutral"
            level="body2"
            underline="none"
            sx={{ mx: 1 }}
          >
            Terms of Service
          </Link>
          <Link
            href="/About_Us"
            color="neutral"
            level="body2"
            underline="none"
            sx={{ mx: 1 }}
          >
            Contact Us
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;