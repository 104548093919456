import React from 'react';
import { useState, useEffect } from 'react';
import { BACKEND_API_URL } from "../data/constant"
import { setPostRequest } from "../other/setdataRequest"
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import Switch from '@mui/joy/Switch';
import Typography from '@mui/joy/Typography';


function BuildIframe(url, iframeKey) {

  if (url == undefined) {
    return <div></div>
  }
  return (
    <div style={{ backgroundImage: "none" }}>
      <iframe
        src={url} // Replace with your desired URL
        Height="1000vh"
        width="100%"
        title="Embedded Content"
        id="myiframe"
      >
        Your browser does not support iframes.
      </iframe>
    </div>
  )
}

function checkIframeReady(url, setLoaded) {

  const urlObject = new URL(url);
  // Get the base URL
  const baseUrl = urlObject.origin + urlObject.pathname;
  fetch(baseUrl, {
    method: 'GET'
  })
    .then((response) => {
      console.log(response)
      if (response.status === 200) {
        setLoaded(true);
      } else if (response.status === 502) {
        // If the status is 502, wait for 1 second and then retry
        setTimeout(() => checkIframeReady(url, setLoaded), 1000);
      }

      // Handle other status codes as needed
    })
    .catch((error) => {
      console.error('Error checking website status:', error);
    });
};

export function Terminal(url, topic) {
  const [data, setData] = useState(null);
  const [cookieData, setCookieData] = useState('');
  const [iframeKey, setIframeKey] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [checked, setChecked] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const myCookieData = Cookies.get('user');
    console.log('handle route change here', location.pathname)
    if (location.pathname.includes('/question') && data == null) {
      console.log("running start container messag")
      setPostRequest(BACKEND_API_URL + url, { "container_name": myCookieData, "user": cookieData }, setData)
    }
  }, [location]); //

  useEffect(() => {
    console.log("running effect for set function")
    console.log(data)
    setIframeKey(prevKey => prevKey + 1);
    console.log("iframe kye", iframeKey)
    if (data != null) {
      checkIframeReady(data['Result'], setLoaded)
    }
  }, [data, setData]);

  return (
    <div style={{ alignContent: "center", alignContent: "center", width: "100%", height: "fit-content" }}>
      {
        data != null && loaded == true ?
          <div>
            { topic == "jenkins" ? <Typography component="label" endDecorator={<Switch
              checked={checked}
              onChange={(event) => setChecked(event.target.checked)}
            />}>
              To GUI
            </Typography> : true}
            {checked ? BuildIframe(new URL(data['Result'].replace(data['Result'].split(".")[0], data['Result'].split(".")[0] + "-gui")).origin, iframeKey) : BuildIframe(data['Result'], iframeKey)}
          </div> :
          <div>Loading Terminal</div>
      }

    </div>
  )
}

export default Terminal;
