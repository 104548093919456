import React, { useState, useEffect } from 'react';
import Terminal from "./terminal"
import Grid from '@mui/joy/Grid';
import Chatroom from './chatroom';
import Cookies from 'js-cookie';
import { useParams, useLocation, useNavigate } from 'react-router';
import { LocalDrinkOutlined } from '@mui/icons-material';

export default function QuestionPanel() {
    const myCookieData = Cookies.get('user');
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    console.log(id)
    useEffect(() => {
        if (location.pathname.includes('/question'))
            console.log("location object: ", location.state)
    }, [location]);
    useEffect(() => {
        if (location.state == null) {
            navigate("/Practice_Now")
        }
    }, []);



    return (
        <Grid container spacing={2} sx={{ height: "100%", width: "100%" }}>
            <Grid xs={10} sx={{ textAlign: "center" }}>
                {location.state == null ? <h2> Loading </h2> :
                    <div>
                        <h2>  {location.state.id} : {location.state.question_title} </h2>
                        <div>
                            {location.state.question_description}
                        </div>
                    </div>
                }
            </Grid>
            <Grid xs={2}>
            </Grid>

            <Grid xs={10} sx={{ textAlign: "center", margin: "auto", height: "fit-content" }}>

                {
                    myCookieData == undefined || location.state === undefined ? <h2> Please Login First </h2> :
                        Terminal("start-container/" + location.state.topic + "/" + location.state.id, location.state.topic)
                }
            </Grid>
            <Grid xs={2} sx={{ textAlign: "center" }}>
                {
                    myCookieData == undefined ? <div> </div> :
                        // Chatroom()
                        <div> </div>
                }
            </Grid>
        </Grid>
    )
}